import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button, Space, Select } from "antd";
import LabelInput from "components/molecules/LabelInput";
import { useRecoilState } from "recoil";
import { ReviewStatusModalState } from "utils/atom";

function ReviewStatusModal() {
  const [modal, setModal] = useRecoilState(ReviewStatusModalState);

  const [type, setType] = useState("사유를 선택해주세요.");
  const [reason, setReason] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { Option } = Select;

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleStatus = (value: any) => {
    if (value === "직접 입력") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setType(value);
  };
  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };
  const handleClick = (value: string) => {
    modal?.onClick(value);
    handleClose();
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>숨김 사유입력</p>
          <Icon onClick={handleClose} />
        </Title>
        <Contents>
          <TextBox>
            <p>사유 선택</p>
            <div>
              <Space style={{ alignItems: "baseline" }}>
                <Select
                  value={type}
                  onChange={(value: any) => handleStatus(value)}
                >
                  <Option value="상대방이 불쾌할 수 있는 리뷰 내용">
                    상대방이 불쾌할 수 있는 리뷰 내용
                  </Option>
                  <Option value="욕설/비방 목적의 리뷰 내용">
                    욕설/비방 목적의 리뷰 내용
                  </Option>
                  <Option value="욕설/비방 목적의 리뷰 내용 거래/상품과 관련없는 리뷰 내용">
                    욕설/비방 목적의 리뷰 내용 거래/상품과 관련없는 리뷰 내용
                  </Option>
                  <Option value="직접 입력">직접 입력</Option>
                </Select>
              </Space>
            </div>
          </TextBox>
          <TextBox>
            <p>사유 직접입력</p>
            <div>
              <LabelInput
                type="textarea"
                style={{ height: "115px" }}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="리뷰 숨김사유를 입력해주세요"
                disabled={disabled}
              />
            </div>
          </TextBox>
        </Contents>
        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleClick(type === "직접 입력" ? reason : type);
            }}
          >
            확인
          </Button>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default ReviewStatusModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 600px;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;
const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  & > p {
    width: 50%;
    height: 100%;
    background: #fafafa;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > div {
    width: 50%;
    padding: 12px 14px;
    & h5 {
      margin: 0;
    }
    & textarea {
      min-height: 115px !important;
      margin: 0;
      padding: 8px 10px;
      &:disabled {
        border: none;
      }
    }
  }
  &:last-of-type {
    border-bottom: 0;
    height: 158px;
  }
  & div.ant-space-item,
  div.ant-select-single,
  div.ant-space {
    width: 100%;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
