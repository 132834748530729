import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button, Select, message } from "antd";
import LabelInput from "components/molecules/LabelInput";
import { useRecoilState } from "recoil";
import { DeliverySendModalState } from "utils/atom";
import useAxios from "api/axios";
import bankData from "./bank.json";
import useSWR from "swr";

function DeliverySendModal() {
  const [modal, setModal] = useRecoilState(DeliverySendModalState);

  const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [account, setAccount] = useState("");
  const [compony, setCompony] = useState("");
  const [number, setNumber] = useState("");
  const axios = useAxios();

  const { data: service } = useSWR(
    modal?.type === "tracking" ? `/service` : ""
  );
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (modal?.status === "register") {
      setBank(String(modal?.bank ?? ""));
      setName(String(modal?.name ?? ""));
      setAccount(String(modal?.account ?? ""));
      setNumber(String(modal?.number ?? ""));
      setCompony(String(modal?.compony ?? ""));
    }
  }, [modal?.status]);

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  // 계좌번호 보내기
  const handleBankClick = async () => {
    handleClose();
    const formdata = new FormData();
    formdata.append("dreamtalk_room_id", String(modal?.roomId));
    formdata.append("user_id", String(modal?.id));
    formdata.append("bank", bank);
    formdata.append("name", name);
    formdata.append("account", account);
    formdata.append("is_individual", "1");

    const { data: submitData } = await axios.post(
      "/admin/dreamtalk/bank/add",
      formdata
    );

    if (submitData.success) {
      window.location.reload();
      modal?.onClick();
    } else {
      message.error(submitData.alert);
    }
  };

  // 운송장 등록
  useEffect(() => {
    if (service) handleList(service?.data?.sweettracker_api_key);
  }, [service]);
  const handleList = async (key: any) => {
    axios
      .get(`http://info.sweettracker.co.kr/api/v1/companylist?t_key=${key}`)
      .then((response: any) => {
        let arr: any = [];

        if (response?.data?.Company) {
          for (const option of response?.data?.Company) {
            arr.push({ value: option?.Code, text: option?.Name });
          }
        }

        setOptions(arr);
      });
  };

  const handleTrackingClick = async () => {
    handleClose();
    const formdata = new FormData();
    if (modal?.personalType) {
      formdata.append("dreamtalk_room_id", String(modal?.roomId));
      formdata.append("user_id", String(modal?.id));
    } else {
      formdata.append("id", String(modal?.id));
    }
    formdata.append("logistics_company", compony);
    formdata.append("tracking_number", number);

    const { data: submitData } = await axios.post(
      modal?.personalType
        ? "/admin/dreamtalk/tracking/number/send"
        : "/admin/order/delivery",
      formdata
    );

    if (submitData.success) {
      // window.location.reload();
      modal?.onClick();
    } else {
      message.error(submitData.alert);
    }
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>{modal?.type === "bank" ? "계좌번호 보내기" : "운송장 등록"}</p>
          <Icon onClick={handleClose} />
        </Title>
        {modal?.type === "bank" ? (
          <Contents>
            <TextBox>
              <p>입금 은행</p>
              <div className="select_Box">
                <Select
                  placeholder="은행명 선택"
                  onChange={(value: any) => {
                    setBank(value);
                  }}
                  value={bank === "" ? undefined : bank}
                >
                  <Select.Option value="">전체</Select.Option>
                  {bankData?.map((option: any, i: any) => (
                    <Select.Option
                      key={"bank_options" + i}
                      value={option?.value}
                    >
                      {option?.text}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </TextBox>
            <TextBox>
              <p>예금주</p>
              <div>
                <LabelInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="예금주 명 입력"
                />
              </div>
            </TextBox>
            <TextBox>
              <p>계좌번호</p>
              <div>
                <LabelInput
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="‘-’를 제외하고 입력"
                />
              </div>
            </TextBox>
          </Contents>
        ) : (
          <Contents>
            <TextBox>
              <p>택배사</p>
              <div className="select_Box">
                <Select
                  placeholder="택배사를 선택해주세요"
                  style={{ minWidth: 150 }}
                  value={compony === "" ? undefined : compony}
                  onChange={(value) => {
                    setCompony(value);
                  }}
                >
                  {options.map((option: any, i: any) => (
                    <Select.Option value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </TextBox>
            <TextBox>
              <p>운송장 번호</p>
              <div>
                <LabelInput
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="운송장 번호를 입력해주세요"
                />
              </div>
            </TextBox>
          </Contents>
        )}
        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              modal?.type === "bank"
                ? handleBankClick()
                : handleTrackingClick();
            }}
          >
            확인
          </Button>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default DeliverySendModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 600px;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;
const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  & > p {
    width: 50%;
    height: 100%;
    background: #fafafa;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > div {
    width: 50%;
    padding: 12px 14px;
    & h5 {
      margin: 0;
    }
    & input {
      margin: 0;
    }
  }
  & div.ant-space-item,
  div.ant-select-single,
  div.ant-space {
    width: 100%;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
