import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button } from "antd";
import { useRecoilState } from "recoil";
import { RefundModalState } from "utils/atom";

function RefundwModal() {
  const [modal, setModal] = useRecoilState(RefundModalState);

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>환불처리요청</p>
          <Icon onClick={handleClose} />
        </Title>
        <Contents>
          <TextBox>
            <p className="title">요청 사유</p>
            <div className="content_box">
              <p
                className="contents"
                dangerouslySetInnerHTML={{ __html: modal?.reason ?? "" }}
              />
            </div>
          </TextBox>
          <TextBox>
            <p className="title">첨부 이미지</p>
            <div className="content_box">
              {modal?.image.map((item, i) => (
                <img
                  //@ts-ignore
                  src={item.image_url}
                  key={`refund_modal_images_list_${i}`}
                />
              ))}
            </div>
          </TextBox>
        </Contents>
        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleClose();
              modal?.handleRefund && modal?.handleRefund();
            }}
          >
            환불요청 수락
          </Button>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default RefundwModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 517px;
  height: 623px;
  overflow: auto;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;

const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  background: #fafafa;
  & p.title {
    width: 50%;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & p.contents {
    border: 1px solid #d9d9d9;
    color: #000;
    min-height: 115px !important;
    margin: 0;
    padding: 8px 10px;
    cursor: context-menu;
  }
  & div.content_box {
    width: 50%;
    min-height: 60px;
    padding: 12px 14px;
    background: #fff;
    & img {
      width: 209px;
      height: 205px;
      border-radius: 9px;
      background-color: gray;
      margin-bottom: 10px;
    }
    & img:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
