import React from "react";
import { Typography, Input, Button } from "antd";
import { InputProps, TextAreaProps } from "antd/lib/input";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

interface LabelProps extends InputProps {
  level?: 1 | 2 | 3 | 4 | 5;
  title?: string;
  type?: string;
  button?: string;
  ref?: any;
}

export default function ({
  type,
  title,
  level,
  button,
  ref,
  ...props
}: LabelProps) {
  const history = useHistory();
  return (
    <Wrapper>
      <Typography.Title style={{ marginLeft: "5px" }} level={level ? level : 5}>
        {title}
        {button && (
          <Button
            onClick={() => {
              sessionStorage.setItem("title", button);
              history.push("search");
            }}
            style={{ width: "80px", padding: "0 10px", marginLeft: "11px" }}
          >
            상품 관리
          </Button>
        )}
      </Typography.Title>
      {type === "textarea" ? (
        //@ts-ignore
        <StyledTextArea {...props} />
      ) : (
        <StyledInput type={type} {...props} ref={ref} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  margin-bottom: 20px;
  min-height: 300px !important;

  resize: unset;
  &.memo {
    min-height: 250px !important;
  }
`;
