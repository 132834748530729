import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button, message, DatePicker, Switch } from "antd";
import LabelInput from "components/molecules/LabelInput";
import { useRecoilState } from "recoil";
import useAxios from "api/axios";
import { DirectSendModalState } from "utils/atom";
import moment from "moment";

function DirectSendModal() {
  const [modal, setModal] = useRecoilState(DirectSendModalState);

  const axios = useAxios();
  const [date, setDate] = useState<any>(null);
  const [place, setPlace] = useState("");
  const [alarm, setAlarm] = useState(false);

  useEffect(() => {
    if (modal?.status === "register") {
      setDate(modal?.dealingDate);
      setPlace(String(modal?.dealingPlace));
      setAlarm(modal?.dealingAlarm === 0 ? false : true);
    }
  }, [modal?.status]);

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };
  const handleReview = async () => {
    handleClose();
    const formdata = new FormData();
    formdata.append("dreamtalk_room_id", String(modal?.roomId));
    formdata.append("user_id", String(modal?.id));
    formdata.append("direct_dealing_date", date);
    formdata.append("direct_dealing_place", place);
    formdata.append("before_30m_alarm", alarm ? "1" : "0");

    const { data: submitData } = await axios.post(
      "/admin/dreamtalk/direct/dealing/send",
      formdata
    );

    if (submitData.success) {
      window.location.reload();
    } else {
      message.error(submitData.alert);
    }
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>직거래 약속</p>
          <Icon onClick={handleClose} />
        </Title>
        <Contents>
          <TextBox>
            <p>약속 시간</p>
            <div>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                placeholder="약속 시간을 선택해주세요"
                showTime
                value={date ? moment(date) : null}
                onChange={(date) => {
                  setDate(moment(date).format("YYYY-MM-DD HH:mm:00"));
                }}
              />
            </div>
          </TextBox>
          <TextBox>
            <p>약속 장소</p>
            <div>
              <LabelInput
                value={place}
                onChange={(e) => setPlace(e.target.value)}
                placeholder="예) 신촌역 4번 출구"
              />
            </div>
          </TextBox>
          <TextBox>
            <p>30분 전 알림 받기</p>
            <div>
              <Switch
                checked={alarm}
                onChange={(checked: boolean) => setAlarm(checked)}
              />
            </div>
          </TextBox>
        </Contents>
        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => handleReview()}
          >
            확인
          </Button>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default DirectSendModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 517px;
  height: fit-content;
  overflow: auto;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;
const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  & > p {
    width: 50%;
    height: 100%;
    background: #fafafa;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > div {
    width: 50%;
    padding: 12px 14px;
    & h5 {
      margin: 0;
    }
    & input {
      margin: 0;
    }
    & .ant-picker {
      width: 100%;
    }
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
