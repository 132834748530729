import { atom } from "recoil";
type reviewStatusModalType = {
  onClick: (value: any) => void;
} | null;

export const ReviewStatusModalState = atom<reviewStatusModalType>({
  key: `reviewStatusModalState`,
  default: null,
});

type reviewStatusDetailModalType = {
  id: number;
  onClick: (value: any) => void;
} | null;

export const ReviewStatusDetailModalState = atom<reviewStatusDetailModalType>({
  key: `reviewStatusDetailModalState`,
  default: null,
});

type reviewModalType = {
  roomId: number;
  id: number;
  type: string;
} | null;

export const ReviewModalState = atom<reviewModalType>({
  key: `reviewModalType`,
  default: null,
});

type deliverySendModalState = {
  roomId: number;
  id: number;
  type: "bank" | "tracking";
  status: "add" | "register";
  bank?: number;
  name?: string;
  account?: number;
  onClick?: any;
  compony?: number;
  number?: number;
  safeType: boolean;
  personalType: boolean;
} | null;

export const DeliverySendModalState = atom<deliverySendModalState>({
  key: `deliverySendModalState`,
  default: null,
});

type cancelModalState = {
  roomId: number;
  id: number;
  orderId?: any;
  type: "delivery" | "order_reject" | "order_cancel";
} | null;

export const CancelModalState = atom<cancelModalState>({
  key: `cancelModalState`,
  default: null,
});

type directSendModalType = {
  roomId: number;
  id: number;
  status: "add" | "register";
  dealingDate?: string;
  dealingPlace?: string;
  dealingAlarm?: number;
} | null;

export const DirectSendModalState = atom<directSendModalType>({
  key: `directSendModalType`,
  default: null,
});

type refundModalType = {
  reason: string;
  image: [];
  handleRefund: () => void;
} | null;

export const RefundModalState = atom<refundModalType>({
  key: `refundModalType`,
  default: null,
});
