import React from "react";
import loadable from "@loadable/component";
import {
  BlockOutlined,
  CommentOutlined,
  CreditCardOutlined,
  ShoppingCartOutlined,
  PushpinOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

export interface ISidebar {
  label: string;
  icon?: React.ReactNode;
}

export interface IRoute {
  exact?: boolean;
  visible?: boolean;
  path: string;
  component?: any;
  children?: Array<IRoute>;
  sidebar?: ISidebar;
}

export const publicRoutes: Array<IRoute> = [
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

interface IPrivateRoute extends IRoute {}

export const privateRoutes: Array<IPrivateRoute> = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <UserOutlined />,
      label: "대시보드",
    },
    children: [
      {
        exact: true,
        path: "/seven-weeks",
        sidebar: {
          label: "7일간 데이터",
        },
        component: loadable(() => import("../pages/dashboard/seven-days")),
      },
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1 문의",
        },
        component: loadable(() => import("../pages/serviceCenter/qna")),
      },
      // {
      //    exact: true,
      //    path: "/pro",
      //    sidebar: {
      //       label: "전문 상점 리스트",
      //    },
      //    component: loadable(() => import("../pages/dashboard/pro")),
      // },
      {
        exact: true,
        path: "/popular",
        sidebar: {
          label: "인기 검색어 리스트",
        },
        component: loadable(() => import("../pages/dashboard/popular")),
      },
      {
        exact: true,
        path: "/delay",
        sidebar: {
          label: "발송 지연 리스트",
        },
        component: loadable(() => import("../pages/dashboard/delay")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user")),
      },
      {
        exact: true,
        visible: false,
        path: "/:userId",
        component: loadable(() => import("../pages/user/userDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/history/:userId",
        component: loadable(() => import("../pages/user/SearchHistory")),
      },
      {
        exact: true,
        visible: false,
        path: "/coupon/:userId",
        component: loadable(() => import("../pages/user/CouponList")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/:userId",
        component: loadable(() => import("../pages/user/OrderHistory")),
      },
    ],
  },
  {
    exact: true,
    path: "/market",
    sidebar: {
      icon: <UserOutlined />,
      label: "상점 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "상점 리스트",
        },
        component: loadable(() => import("../pages/market")),
      },
      {
        exact: true,
        visible: false,
        path: "/:marketId",
        component: loadable(() => import("../pages/market/marketDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/sadadream/:userId",
        component: loadable(() => import("../pages/market/sadadream")),
      },
      {
        exact: true,
        visible: false,
        path: "/sadadream/detail/:productId",
        component: loadable(() => import("../pages/market/sadadream/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/dream-market/:userId",
        component: loadable(() => import("../pages/market/dream-market")),
      },
      {
        exact: true,
        visible: false,
        path: "/order/:userId",
        component: loadable(() => import("../pages/market/orderList")),
      },
      {
        exact: true,
        visible: false,
        path: "/dream-market/detail/:productId",
        component: loadable(
          () => import("../pages/market/dream-market/Detail")
        ),
      },
      {
        exact: true,
        sidebar: {
          label: "전문 상점 관리",
        },
        path: "/pro",
        children: [
          {
            exact: true,
            sidebar: {
              label: "전문 상점 리스트",
            },
            path: "/index",
            component: loadable(() => import("../pages/market/pro")),
          },
          {
            exact: true,
            visible: false,
            path: "/detail/:marketId",
            component: loadable(() => import("../pages/market/pro/Detail")),
          },
          {
            exact: true,
            sidebar: {
              label: "신청 리스트",
            },
            path: "/request",
            component: loadable(() => import("../pages/market/pro/request")),
          },
          {
            exact: true,
            sidebar: {
              label: "신청 거절 리스트",
            },
            path: "/reject",
            component: loadable(() => import("../pages/market/pro/reject")),
          },
        ],
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체 상품 리스트",
        },
        component: loadable(() => import("../pages/product")),
      },
      {
        exact: true,
        path: "/dream-market",
        sidebar: {
          label: "드림마켓 상품 리스트",
        },
        component: loadable(() => import("../pages/product/dream-market")),
      },
      {
        exact: true,
        path: "/dream-market/:productId",
        visible: false,
        component: loadable(
          () => import("../pages/product/dream-market/Submit")
        ),
      },
      {
        exact: true,
        path: "/dream-market/options/:productId",
        visible: false,
        component: loadable(
          () => import("../pages/product/dream-market/OptionSubmit")
        ),
      },
      {
        exact: true,
        path: "/dream-market/detail/:productId",
        visible: false,
        component: loadable(
          () => import("../pages/product/dream-market/Detail")
        ),
      },
      {
        exact: true,
        path: "/sadadream",
        sidebar: {
          label: "사다드림 상품 리스트",
        },
        component: loadable(() => import("../pages/product/sadadream")),
      },
      {
        exact: true,
        path: "/sadadream/detail/:productId",
        visible: false,
        component: loadable(() => import("../pages/product/sadadream/Detail")),
      },
      {
        exact: true,
        path: "/sadadream/:productId",
        visible: false,
        component: loadable(() => import("../pages/product/sadadream/Submit")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "주문 리스트",
        },
        component: loadable(() => import("../pages/order")),
      },
      {
        exact: true,
        visible: false,
        path: "/:orderId",
        component: loadable(() => import("../pages/order/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/operation",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "운영 관리",
    },
    children: [
      {
        exact: true,
        path: "/plan",
        sidebar: {
          label: "기획전",
        },
        component: loadable(() => import("../pages/operation/plan")),
      },
      {
        exact: true,
        path: "/plan/detail/:planId",
        visible: false,
        component: loadable(() => import("../pages/operation/plan/Detail")),
      },
      {
        exact: true,
        path: "/plan/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/plan/Submit")),
      },
      {
        exact: true,
        path: "/discount",
        sidebar: {
          label: "할인률 조회",
        },
        component: loadable(
          () => import("../pages/operation/recommend/DiscountRate")
        ),
      },
      {
        exact: true,
        path: "/event",
        sidebar: {
          label: "이벤트 리스트",
        },
        component: loadable(() => import("../pages/operation/event")),
      },
      {
        exact: true,
        path: "/event/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/event/Submit")),
      },
      {
        exact: true,
        path: "/popular",
        sidebar: {
          label: "인기검색어 리스트",
        },
        component: loadable(() => import("../pages/operation/popular")),
      },
      {
        exact: true,
        path: "/popular/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/popular/Submit")),
      },
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "배너 리스트",
        },
        component: loadable(() => import("../pages/operation/banner")),
      },
      {
        exact: true,
        path: "/banner/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/banner/Submit")),
      },
      {
        exact: true,
        path: "/popup",
        sidebar: {
          label: "이벤트 팝업 리스트",
        },
        component: loadable(() => import("../pages/operation/popup/index")),
      },
      {
        exact: true,
        path: "/popup/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/popup/Submit")),
      },
      {
        exact: true,
        path: "/sada",
        sidebar: {
          label: "사다드림 키워드 리스트",
        },
        component: loadable(() => import("../pages/operation/sadadream/index")),
      },
      {
        exact: true,
        path: "/sada/detail/:planId",
        visible: false,
        component: loadable(
          () => import("../pages/operation/sadadream/Detail")
        ),
      },
      {
        exact: true,
        path: "/sada/submit",
        visible: false,
        component: loadable(
          () => import("../pages/operation/sadadream/Submit")
        ),
      },
      {
        exact: true,
        path: "/guide",
        sidebar: {
          label: "이용 가이드 관리",
        },
        component: loadable(() => import("../pages/operation/guide/index")),
      },
      {
        exact: true,
        path: "/guide/submit",
        visible: false,
        component: loadable(() => import("../pages/operation/guide/Submit")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "운영 사이트 설정",
        },
        component: loadable(() => import("../pages/site/site")),
      },
      {
        exact: true,
        path: "/user",
        sidebar: {
          label: "회원관련 설정",
        },
        component: loadable(() => import("../pages/site/user")),
      },
      {
        exact: true,
        path: "/terms",
        sidebar: {
          label: "약관 관리",
        },
        component: loadable(() => import("../pages/site/terms")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "포인트 관리",
        },
        component: loadable(() => import("../pages/site/point")),
      },
      {
        exact: true,
        path: "/point/submit",
        visible: false,
        component: loadable(() => import("../pages/site/point/Submit")),
      },
      {
        exact: true,
        path: "/message",
        sidebar: {
          label: "상황별 문자 설정",
        },
        component: loadable(() => import("../pages/site/message")),
      },
      {
        exact: true,
        path: "/home",
        sidebar: {
          label: "홈 화면 관리",
        },
        component: loadable(() => import("../pages/site/home")),
      },
      {
        exact: true,
        path: "/search",
        visible: false,
        component: loadable(() => import("../pages/site/search")),
      },
      {
        exact: true,
        path: "/brand/part",
        sidebar: {
          label: "브랜드관 관리",
        },
        component: loadable(() => import("../pages/brand/Part")),
      },
      {
        exact: true,
        path: "/brand/part/submit",
        visible: false,
        component: loadable(() => import("../pages/brand/PartSubmit")),
      },
      {
        exact: true,
        path: "/brand",
        sidebar: {
          label: "브랜드 관리",
        },
        component: loadable(() => import("../pages/brand/List")),
      },
      {
        exact: true,
        path: "/brand/submit",
        visible: false,
        component: loadable(() => import("../pages/brand/Submit")),
      },
      {
        exact: true,
        path: "/lucky_draw",
        sidebar: {
          label: "럭키 드로우 관리",
        },
        component: loadable(() => import("../pages/lucky/List")),
      },
      {
        exact: true,
        path: "/lucky_draw/submit",
        visible: false,
        component: loadable(() => import("../pages/lucky/Submit")),
      },
      {
        exact: true,
        path: "/lucky_draw/user",
        visible: false,
        component: loadable(() => import("../pages/lucky/UserList")),
      },
      {
        exact: true,
        path: "/login_page",
        sidebar: {
          label: "로그인 페이지 관리",
        },
        component: loadable(() => import("../pages/site/login")),
      },
    ],
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "쿠폰 관리",
    },
    children: [
      {
        exact: true,
        path: "/",
        sidebar: {
          label: "쿠폰 리스트",
        },
        component: loadable(() => import("../pages/coupon")),
      },
      {
        exact: true,
        path: "/submit",
        visible: false,
        component: loadable(() => import("../pages/coupon/Submit")),
      },
      {
        exact: true,
        path: "/give/:couponId",
        visible: false,
        component: loadable(() => import("../pages/coupon/Give")),
      },
      {
        exact: true,
        visible: false,
        path: "/history/:couponId",
        component: loadable(() => import("../pages/coupon/Histories")),
      },
      {
        exact: true,
        path: "/auto",
        sidebar: {
          label: "가입쿠폰 자동지급 설정",
        },
        component: loadable(() => import("../pages/coupon/Auto")),
      },
      {
        exact: true,
        path: "/auto/submit",
        visible: false,
        component: loadable(() => import("../pages/coupon/AutoSubmit")),
      },
    ],
  },
  {
    exact: true,
    path: "/service",
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ",
        },
        component: loadable(() => import("../pages/serviceCenter/faq")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/submit",
        component: loadable(() => import("../pages/serviceCenter/faq/Submit")),
      },
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1 문의",
        },
        component: loadable(() => import("../pages/serviceCenter/qna")),
      },
      {
        exact: true,
        path: "/qna/submit",
        visible: false,
        component: loadable(() => import("../pages/serviceCenter/qna/Submit")),
      },
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항 관리",
        },
        component: loadable(() => import("../pages/serviceCenter/notice")),
      },
      {
        exact: true,
        path: "/notice/submit",
        visible: false,
        component: loadable(
          () => import("../pages/serviceCenter/notice/Submit")
        ),
      },
      {
        exact: true,
        path: "/report",
        sidebar: {
          label: "신고 관리",
        },
        component: loadable(() => import("../pages/serviceCenter/report")),
      },
      {
        exact: true,
        path: "/review",
        sidebar: {
          label: "리뷰 관리",
        },
        component: loadable(() => import("../pages/serviceCenter/review")),
      },
      {
        exact: true,
        path: "/report/hide/:userId",
        visible: false,
        component: loadable(
          () => import("../pages/serviceCenter/report/HideProduct")
        ),
      },
      {
        exact: true,
        path: "/report/:reportId",
        visible: false,
        component: loadable(
          () => import("../pages/serviceCenter/report/Detail")
        ),
      },
      {
        exact: true,
        path: "/alarm",
        sidebar: {
          label: "알림 발송",
        },
        component: loadable(() => import("../pages/serviceCenter/alarm")),
      },
      {
        exact: true,
        path: "/alarm/:editId",
        visible: false,
        component: loadable(() => import("../pages/serviceCenter/alarm")),
      },
      {
        exact: true,
        path: "/alarm_list",
        sidebar: {
          label: "알림 리스트",
        },
        component: loadable(() => import("../pages/serviceCenter/alarm/List")),
      },
      {
        exact: true,
        path: "/alarm_list/:id",
        visible: false,
        component: loadable(
          () => import("../pages/serviceCenter/alarm/Detail")
        ),
      },
      {
        exact: true,
        path: "/dreamtalk",
        sidebar: {
          label: "드림톡 리스트",
        },
        component: loadable(() => import("../pages/serviceCenter/dreamtalk")),
      },
      {
        exact: true,
        path: "/dreamtalk/:talkId",
        visible: false,
        component: loadable(
          () => import("../pages/serviceCenter/dreamtalk/Detail")
        ),
      },
    ],
  },
  {
    exact: true,
    path: "/account",
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: "회계/정산",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산",
        },
        component: loadable(() => import("../pages/account")),
      },
      {
        exact: true,
        path: "/detail/:accountId",
        visible: false,
        component: loadable(() => import("../pages/account/Detail")),
      },
      {
        exact: true,
        path: "/detail/:accountId/:buyerId/:orderNumber",
        visible: false,
        component: loadable(() => import("../pages/account/Detail")),
      },
      {
        exact: true,
        path: "/accounting",
        sidebar: {
          label: "회계",
        },
        component: loadable(() => import("../pages/account/accounting")),
      },
    ],
  },
  {
    exact: true,
    path: "/numerical",
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: "통계",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "통계 리스트",
        },
        component: loadable(() => import("../pages/numerical/order")),
      },
    ],
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <QuestionCircleOutlined />,
      label: "관리자 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "관리자 리스트",
        },
        component: loadable(() => import("../pages/admin")),
      },
      {
        exact: true,
        path: "/submit",
        visible: false,
        component: loadable(() => import("../pages/admin/Submit")),
      },
      {
        exact: true,
        path: "/market-admin",
        sidebar: {
          label: "관리자 상점",
        },
        children: [
          {
            exact: true,
            path: "/market",
            sidebar: {
              label: "상점 리스트",
            },
            component: loadable(
              () => import("../pages/admin/marketAdmin/market")
            ),
          },
        ],
      },
      {
        exact: true,
        path: "/product-admin",
        sidebar: {
          label: "관리자 상품",
        },
        children: [
          {
            exact: true,
            path: "/dream-market",
            sidebar: {
              label: "드림마켓 리스트",
            },
            component: loadable(
              () => import("../pages/admin/productAdmin/dream-market")
            ),
          },
          {
            exact: true,
            path: "/dream-market/detail/:productId",
            visible: false,
            component: loadable(
              () => import("../pages/product/dream-market/Detail")
            ),
          },
          {
            exact: true,
            path: "/dream-market/submit",
            visible: false,
            component: loadable(
              () => import("../pages/admin/productAdmin/dream-market/Submit")
            ),
          },
          {
            exact: true,
            path: "/dream-market/edit/:productId",
            visible: false,
            component: loadable(
              () => import("../pages/admin/productAdmin/dream-market/Submit")
            ),
          },
          {
            exact: true,
            path: "/sadadream",
            sidebar: {
              label: "사다드림 리스트",
            },
            component: loadable(
              () => import("../pages/admin/productAdmin/sadadream")
            ),
          },
          {
            exact: true,
            path: "/sadadream/detail/:productId",
            visible: false,
            component: loadable(
              () => import("../pages/product/sadadream/Detail")
            ),
          },
          {
            exact: true,
            path: "/sadadream/submit",
            visible: false,
            component: loadable(
              () => import("../pages/admin/productAdmin/sadadream/Submit")
            ),
          },
          {
            exact: true,
            path: "/sadadream/edit/:productId",
            visible: false,
            component: loadable(
              () => import("../pages/admin/productAdmin/sadadream/Submit")
            ),
          },
        ],
      },
      {
        exact: true,
        path: "/order-admin",
        sidebar: {
          label: "주문 리스트",
        },
        component: loadable(() => import("../pages/admin/order")),
      },
      {
        exact: true,
        path: "/order-admin/:orderId",
        visible: false,
        component: loadable(() => import("../pages/order/Detail")),
      },
    ],
  },
];
