import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button, message } from "antd";
import LabelInput from "components/molecules/LabelInput";
import { useRecoilState } from "recoil";
import { CancelModalState } from "utils/atom";
import useAxios from "api/axios";

function CancelModal() {
  const [modal, setModal] = useRecoilState(CancelModalState);

  const [reason, setReason] = useState("");
  const axios = useAxios();

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };
  // 거래 취소
  const handleDelivery = async () => {
    handleClose();
    const formdata = new FormData();
    formdata.append("dreamtalk_room_id", String(modal?.roomId));
    formdata.append("user_id", String(modal?.id));
    formdata.append("reason", reason);

    const { data: submitData } = await axios.post(
      "/admin/dreamtalk/cancel",
      formdata
    );

    if (submitData.success) {
      window.location.reload();
    } else {
      message.error(submitData.alert);
    }
  };
  // 판매 거절
  const handleReject = async () => {
    handleClose();
    const formdata = new FormData();
    modal?.orderId?.map((item: any) => {
      formdata.append("order_detail_ids[]", item);
    });
    formdata.append("user_id", String(modal?.id));
    formdata.append("reason", reason);

    const { data: submitData } = await axios.post(
      "/admin/dreamtalk/order/reject",
      formdata
    );

    if (submitData.success) {
      window.location.reload();
    } else {
      message.error(submitData.alert);
    }
  };
  // 주문 취소 거절
  const handleCancel = async () => {
    handleClose();
    const formdata = new FormData();
    modal?.orderId?.map((item: any) => {
      formdata.append("order_detail_ids[]", item);
    });
    formdata.append("user_id", String(modal?.id));
    formdata.append("reason", reason);

    const { data: submitData } = await axios.post(
      "/admin/dreamtalk/order/cancel/reject",
      formdata
    );

    if (submitData.success) {
      window.location.reload();
    } else {
      message.error(submitData.alert);
    }
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>
            {modal?.type === "delivery"
              ? "거래 취소"
              : modal?.type === "order_reject"
              ? "판매 거절"
              : "주문 취소 거절"}
          </p>
          <Icon onClick={handleClose} />
        </Title>

        <Contents>
          {modal?.type === "delivery" ? (
            <TextBox>
              <p>취소 사유</p>
              <div>
                <LabelInput
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="전달할 취소 사유 입력"
                />
              </div>
            </TextBox>
          ) : (
            <TextBox className="cancel">
              <p>거절 사유</p>
              <div>
                <LabelInput
                  type="textarea"
                  style={{ height: "115px" }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="거절 사유를 입력해주세요"
                />
              </div>
            </TextBox>
          )}
        </Contents>

        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              {
                modal?.type === "delivery"
                  ? handleDelivery()
                  : modal?.type === "order_reject"
                  ? handleReject()
                  : handleCancel();
              }
            }}
          >
            처리
          </Button>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default CancelModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 600px;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;
const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  &.cancel {
    height: 158px;
  }
  & > p {
    width: 50%;
    height: 100%;
    background: #fafafa;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > div {
    width: 50%;
    padding: 12px 14px;
    & h5 {
      margin: 0;
    }
    & input {
      margin: 0;
    }
    & textarea {
      min-height: 115px !important;
      margin: 0;
      padding: 8px 10px;
    }
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
