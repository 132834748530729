import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/close_icon.svg";
import { Button, Space, Select, Checkbox, Popconfirm } from "antd";
import LabelInput from "components/molecules/LabelInput";
import { useRecoilState } from "recoil";
import { ReviewStatusDetailModalState } from "utils/atom";
import useSWR from "swr";

function ReviewStatusDetailModal() {
  const [modal, setModal] = useRecoilState(ReviewStatusDetailModalState);

  const { data: detail } = useSWR(
    modal && `/admin/review/detail?review_id=${modal?.id}`
  );
  const data = detail?.data;

  const [formData, setFormData] = useState({
    type: 0,
    contents: "",
  });
  const { Option } = Select;
  const { type, contents } = formData;

  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        type: data.review.type,
        contents: data.review.contents,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!modal) return;
    document.documentElement.style.overflow = `hidden`;
    return () => {
      document.documentElement.style.overflow = `auto`;
    };
  }, [modal]);

  const handleClose = () => {
    setTimeout(() => {
      setModal(null);
    }, 300);
  };
  const handleClick = () => {
    modal?.onClick(modal?.id);
    handleClose();
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <PopupContents>
        <Title>
          <p>리뷰 상세</p>
          <Icon onClick={handleClose} />
        </Title>
        <Contents>
          <TextBox>
            <p>거래는 어떠셨나요?</p>
            <div>
              <Space style={{ alignItems: "baseline" }}>
                <Select value={type} disabled={true}>
                  <Option value={0}>최고예요!</Option>
                  <Option value={1}>별로예요.</Option>
                </Select>
              </Space>
            </div>
          </TextBox>
          {data?.manners?.map((item: any, i: number) => (
            <TextBox key={`review_status_detail_${i}`}>
              <p>{item.contents}</p>
              <Checkbox
                value={item.is_check === 1}
                checked={item.is_check === 1}
              />
            </TextBox>
          ))}
          <TextBox>
            <p>거래후기 상세내용</p>
            <div>
              <p
                className="disabled_contents"
                dangerouslySetInnerHTML={{ __html: contents }}
              ></p>
            </div>
          </TextBox>
        </Contents>
        <ButtonBox>
          <Button onClick={handleClose}>닫기</Button>
          <Popconfirm
            title="리뷰를 삭제하시겠습니까?"
            onConfirm={() => handleClick()}
            okText="삭제"
            cancelText="닫기"
          >
            <Button danger type="primary" style={{ marginLeft: "10px" }}>
              삭제
            </Button>
          </Popconfirm>
        </ButtonBox>
      </PopupContents>
    </Wrapper>
  );
}

export default ReviewStatusDetailModal;
const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContents = styled.div`
  position: relative;
  width: 517px;
  height: 623px;
  overflow: auto;
  background: #ffffff;
  padding: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
`;
const Contents = styled.div`
  margin: 35px 0 30px;
`;
const TextBox = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  & > p {
    width: 50%;
    height: 100%;
    background: #fafafa;
    padding: 19px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16.28px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  & > label {
    padding: 12px 14px;
    & span.ant-checkbox-inner {
      width: 23px;
      height: 23px;
    }
  }
  & > div {
    width: 50%;
    padding: 12px 14px;
    & h5 {
      margin: 0;
    }
    & p.disabled_contents {
      background: #e0e0e0;
      color: #000;
      min-height: 115px !important;
      margin: 0;
      padding: 8px 10px;
      cursor: context-menu;
    }
    &
      .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border: none;
      background: #e0e0e0;
      color: #000;
    }
  }
  &:last-of-type {
    height: 158px;
  }
  & div.ant-space-item,
  div.ant-select-single,
  div.ant-space {
    width: 100%;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
